import React from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import Sun from "./Sun";
import Moon from "./Moon";

export default function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <div
          onClick={() => toggleTheme(theme === "dark" ? "light" : "dark")}
          className="ml-2 cursor-pointer flex items-center"
        >
          {/* {!theme && (
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.95012 2.35084C9.07913 2.57976 9.06164 2.86311 8.90544 3.07442C8.33597 3.84486 8.06193 4.79411 8.13318 5.74952C8.20442 6.70493 8.61622 7.60303 9.29367 8.28048C9.97113 8.95793 10.8692 9.36973 11.8246 9.44098C12.78 9.51223 13.7293 9.23819 14.4997 8.66871C14.711 8.51252 14.9944 8.49502 15.2233 8.62404C15.4522 8.75305 15.584 9.00451 15.5598 9.26617C15.4433 10.5271 14.9701 11.7287 14.1956 12.7305C13.4211 13.7322 12.3772 14.4927 11.1863 14.9229C9.99533 15.353 8.70649 15.4351 7.47058 15.1596C6.23467 14.884 5.1028 14.2621 4.20741 13.3667C3.31203 12.4714 2.69017 11.3395 2.41459 10.1036C2.13901 8.86766 2.22111 7.57882 2.65129 6.38787C3.08147 5.19691 3.84194 4.1531 4.8437 3.37858C5.84547 2.60405 7.0471 2.13084 8.30799 2.01431C8.56965 1.99013 8.8211 2.12191 8.95012 2.35084ZM7.10693 3.63753C6.58852 3.82509 6.09973 4.09284 5.65925 4.4334C4.85784 5.05302 4.24947 5.88807 3.90533 6.84083C3.56118 7.7936 3.4955 8.82467 3.71596 9.8134C3.93642 10.8021 4.43392 11.7076 5.15022 12.4239C5.86653 13.1402 6.77203 13.6377 7.76076 13.8582C8.74949 14.0787 9.78056 14.013 10.7333 13.6688C11.6861 13.3247 12.5211 12.7163 13.1408 11.9149C13.4813 11.4744 13.7491 10.9856 13.9366 10.4672C13.2345 10.7212 12.4815 10.827 11.7255 10.7706C10.4516 10.6756 9.25413 10.1266 8.35087 9.22329C7.4476 8.32002 6.89853 7.12255 6.80354 5.84867C6.74716 5.09269 6.85292 4.33961 7.10693 3.63753Z"
                class="fill-black dark:fill-white opacity-0"
              />
            </svg>
          )} */}
          <Sun />
          <Moon />
          {/* {theme && theme === "dark" ? <Sun /> : <Moon />} */}
          {/* {theme && theme !== "dark" && <Moon />} */}

          {/* {theme && theme === "dark" && (
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.95012 2.35084C9.07913 2.57976 9.06164 2.86311 8.90544 3.07442C8.33597 3.84486 8.06193 4.79411 8.13318 5.74952C8.20442 6.70493 8.61622 7.60303 9.29367 8.28048C9.97113 8.95793 10.8692 9.36973 11.8246 9.44098C12.78 9.51223 13.7293 9.23819 14.4997 8.66871C14.711 8.51252 14.9944 8.49502 15.2233 8.62404C15.4522 8.75305 15.584 9.00451 15.5598 9.26617C15.4433 10.5271 14.9701 11.7287 14.1956 12.7305C13.4211 13.7322 12.3772 14.4927 11.1863 14.9229C9.99533 15.353 8.70649 15.4351 7.47058 15.1596C6.23467 14.884 5.1028 14.2621 4.20741 13.3667C3.31203 12.4714 2.69017 11.3395 2.41459 10.1036C2.13901 8.86766 2.22111 7.57882 2.65129 6.38787C3.08147 5.19691 3.84194 4.1531 4.8437 3.37858C5.84547 2.60405 7.0471 2.13084 8.30799 2.01431C8.56965 1.99013 8.8211 2.12191 8.95012 2.35084ZM7.10693 3.63753C6.58852 3.82509 6.09973 4.09284 5.65925 4.4334C4.85784 5.05302 4.24947 5.88807 3.90533 6.84083C3.56118 7.7936 3.4955 8.82467 3.71596 9.8134C3.93642 10.8021 4.43392 11.7076 5.15022 12.4239C5.86653 13.1402 6.77203 13.6377 7.76076 13.8582C8.74949 14.0787 9.78056 14.013 10.7333 13.6688C11.6861 13.3247 12.5211 12.7163 13.1408 11.9149C13.4813 11.4744 13.7491 10.9856 13.9366 10.4672C13.2345 10.7212 12.4815 10.827 11.7255 10.7706C10.4516 10.6756 9.25413 10.1266 8.35087 9.22329C7.4476 8.32002 6.89853 7.12255 6.80354 5.84867C6.74716 5.09269 6.85292 4.33961 7.10693 3.63753Z"
                class="fill-black dark:fill-white"
              />
            </svg>
          )}
          {theme && theme !== "dark" && (
            <svg
              width="17"
              height="17"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_19_85)">
                <path
                  d="M12 0C12.5523 0 13 0.447715 13 1V3C13 3.55228 12.5523 4 12 4C11.4477 4 11 3.55228 11 3V1C11 0.447715 11.4477 0 12 0Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  d="M13 21C13 20.4477 12.5523 20 12 20C11.4477 20 11 20.4477 11 21V23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V21Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  d="M3.51289 3.51289C3.90342 3.12237 4.53658 3.12237 4.92711 3.51289L6.34711 4.93289C6.73763 5.32342 6.73763 5.95658 6.34711 6.34711C5.95658 6.73763 5.32342 6.73763 4.93289 6.34711L3.51289 4.92711C3.12237 4.53658 3.12237 3.90342 3.51289 3.51289Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  d="M19.0671 17.6529C18.6766 17.2624 18.0434 17.2624 17.6529 17.6529C17.2624 18.0434 17.2624 18.6766 17.6529 19.0671L19.0729 20.4871C19.4634 20.8776 20.0966 20.8776 20.4871 20.4871C20.8776 20.0966 20.8776 19.4634 20.4871 19.0729L19.0671 17.6529Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  d="M0 12C0 11.4477 0.447715 11 1 11H3C3.55228 11 4 11.4477 4 12C4 12.5523 3.55228 13 3 13H1C0.447715 13 0 12.5523 0 12Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  d="M21 11C20.4477 11 20 11.4477 20 12C20 12.5523 20.4477 13 21 13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11H21Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  d="M6.34711 17.6529C6.73763 18.0434 6.73763 18.6766 6.34711 19.0671L4.92711 20.4871C4.53658 20.8776 3.90342 20.8776 3.51289 20.4871C3.12237 20.0966 3.12237 19.4634 3.51289 19.0729L4.93289 17.6529C5.32342 17.2624 5.95658 17.2624 6.34711 17.6529Z"
                  class="fill-black dark:fill-white"
                />
                <path
                  d="M20.4871 4.92711C20.8776 4.53658 20.8776 3.90342 20.4871 3.51289C20.0966 3.12237 19.4634 3.12237 19.0729 3.51289L17.6529 4.93289C17.2624 5.32342 17.2624 5.95658 17.6529 6.34711C18.0434 6.73763 18.6766 6.73763 19.0671 6.34711L20.4871 4.92711Z"
                  class="fill-black dark:fill-white"
                />
              </g>
              <defs>
                <clipPath id="clip0_19_85">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )} */}
        </div>
      )}
    </ThemeToggler>
  );
}
