import React from "react";

export default function HeaderGradient() {
  return (
    <div className="relative overflow-hidden w-[100vw] sm:w-[95vw] sm:ml-[2.5vw]  h-[40rem]">
      <svg
        width="1375"
        height="354"
        viewBox="0 0 1375 354"
        fill="none"
        className="absolute top-0 right-[-42rem] sm:right-0 sm:left-0 "
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <g opacity="0.6" filter="url(#filter0_f_15_89)">
            <path
              d="M1006.79 -55.0435C956.723 -46.2462 917.577 -22.9616 902.033 5.44518C886.934 33.0377 901.608 62.1508 929.415 86.3515C956.948 110.315 997.734 130.159 1047.97 132.462C1097.13 134.716 1139.93 117.567 1174.48 97.5887C1209.07 77.5879 1239.63 52.8989 1233.66 25.0791C1227.69 -2.76716 1187.5 -22.5214 1145.53 -37.3409C1103.19 -52.2959 1054.43 -63.4148 1006.79 -55.0435Z"
              fill="url(#paint0_linear_15_89)"
            />
          </g>
          <g opacity="0.9" filter="url(#filter1_f_15_89)">
            <path
              d="M710.057 -222.734C639.363 -211.281 600.08 -169.478 560.393 -132.047C523.245 -97.0103 486.942 -60.2354 493.763 -18.7344C501.009 25.3537 540.067 65.1483 597.298 92.4996C657.393 121.219 732.38 137.167 808.204 131.541C887.454 125.661 967.238 103.701 1008.32 62.0063C1047.45 22.3009 1022.58 -26.1334 1008.41 -71.6306C994.681 -115.715 985.931 -163.128 929.287 -191.816C869.329 -222.183 785.415 -234.944 710.057 -222.734Z"
              fill="url(#paint1_linear_15_89)"
            />
          </g>
          <g filter="url(#filter2_f_15_89)">
            <path
              opacity="0.6"
              d="M529.863 -70.2402C490.917 -68.5017 451.07 -50.0755 434.871 -21.3473C420.049 4.93575 442.382 33.6047 456.305 60.2084C465.801 78.3489 479.837 94.0823 500.64 104.307C519.42 113.537 541.287 111.261 563.181 112.738C597.259 115.036 634.966 132.319 661.818 115.177C690.59 96.8101 696.587 61.4545 688.959 32.4406C681.684 4.7762 651.019 -12.1455 623.701 -29.777C594.892 -48.3689 566.65 -71.883 529.863 -70.2402Z"
              fill="#0500FF"
            />
          </g>
          <g opacity="0.9" filter="url(#filter3_f_15_89)">
            <path
              opacity="0.6"
              d="M326.392 -98.2159C270.129 -83.1521 217.671 -44.8659 202.854 -0.123028C189.302 40.8126 230.655 72.9325 259.121 104.951C278.531 126.786 303.825 143.833 337.298 151.111C367.516 157.68 398.705 147.436 431.086 142.338C481.487 134.405 541.775 145.879 575.677 113.588C612.001 78.9874 609.908 28.46 589.885 -8.91994C570.793 -44.5605 520.885 -57.8295 475.642 -73.1621C427.93 -89.3301 379.533 -112.444 326.392 -98.2159Z"
              fill="url(#paint2_linear_15_89)"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_15_89"
            x="755.985"
            y="-197.92"
            width="618.425"
            height="470.582"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="70"
              result="effect1_foregroundBlur_15_89"
            />
          </filter>
          <filter
            id="filter1_f_15_89"
            x="292.931"
            y="-427.326"
            width="936.404"
            height="759.987"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_15_89"
            />
          </filter>
          <filter
            id="filter2_f_15_89"
            x="269.985"
            y="-230.322"
            width="582"
            height="513"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="80"
              result="effect1_foregroundBlur_15_89"
            />
          </filter>
          <filter
            id="filter3_f_15_89"
            x="0.200806"
            y="-302.656"
            width="803.958"
            height="655.899"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_15_89"
            />
          </filter>
          <linearGradient
            id="paint0_linear_15_89"
            x1="999.45"
            y1="9.3444"
            x2="1344.48"
            y2="164.816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFE8C7" />
            <stop offset="1" stop-color="#FFCD1B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_15_89"
            x1="718.242"
            y1="-17.9159"
            x2="1166.57"
            y2="185.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF0099" />
            <stop offset="1" stop-color="#FFD600" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_15_89"
            x1="244.668"
            y1="40.24"
            x2="520.39"
            y2="17.0418"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#99FFF3" />
            <stop offset="1" stop-color="#0038FF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
